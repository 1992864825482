import React from "react"

function Home(){
    const envi =  process.env.REACT_APP_API
    console.log(envi)
    return(
        <p className="text-black">Hola</p>
    )
}

export default Home