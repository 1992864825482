import React from "react"

function AvisoPrivacidad(){
    return(
        <div className="bg-gray-100 dark:bg-neutral-800 w-full min-h-screen m-0 p-0">
            <div className="grid place-items-center p-4 h-full  mx-[10%]">
                <Title>Política de privacidad</Title>
                <Subtitle>PROTEGER TU INFORMACIÓN</Subtitle>
                <div className="text-justify">
                    <Text>
                        En CargoHub, integramos la seguridad en nuestros servicios para proteger la información de nuestros usuarios. Nos esforzamos por mantener tus datos seguros mediante diversas funciones de seguridad. 
                    </Text>
                    <Text>
                        Aquí te explicamos cómo lo hacemos:                    
                    </Text>
                    <Points
                        items={
                            [
                                {id:1,content:'Cifrado de Datos: Utilizamos cifrado para mantener la privacidad de los datos de los usuarios mientras están en tránsito.'}, 
                                {id:2,content:'Funciones de Seguridad: Contamos con herramientas como Navegación Segura, la revisión de seguridad y la verificación en dos pasos para ayudarte a proteger tu cuenta.'}, 
                                {id:3,content:'Prácticas de Seguridad: Revisamos nuestras prácticas de recogida, almacenamiento y tratamiento de datos, incluidas medidas de seguridad físicas, para evitar el acceso no autorizado a nuestros sistemas.'}, 
                                {id:4,content:'Acceso Restringido: Restringimos el acceso a la información personal a aquellos empleados, contratistas y agentes de CargoHub que necesitan dicha información para procesarla. Todos ellos están sujetos a estrictas obligaciones contractuales de confidencialidad y pueden ser sancionados o despedidos si no las cumplen.'}, 
                            ]
                        }
                    />
                    <Text>
                        Si detectamos algún riesgo que consideramos importante, nos pondremos en contacto contigo para indicarte los pasos que debes seguir para mejorar tu protección.                   
                    </Text>
                </div>
                <Subtitle>CONSERVAR TUS DATOS</Subtitle>
                <div className="text-justify">                   
                    <Text>
                        Conservamos los datos en función de su tipo y de cómo los usamos:                    
                    </Text>
                    <MidlePoints
                        items={
                            [
                                {id:'Datos Personales y Contenido',content:'Puedes eliminar tu información personal o el contenido que subes, como fotos y documentos, cuando quieras. También puedes optar por eliminar automáticamente esta información después de un período determinado.'}, 
                                {id:'Datos Publicitarios y Registros de Servidor',content:'Estos datos se eliminan o se anonimizan después de un período determinado.'}, 
                                {id:'Frecuencia de Uso',content:'Algunos datos, como la información sobre la frecuencia con la que usas nuestros servicios, se conservan hasta que elimines tu cuenta.'}, 
                            ]
                        }
                    />
                </div>
                <Subtitle>CUMPLIMIENTO Y COOPERACIÓN CON AUTORIDADES REGULADORAS</Subtitle>
                <Text>
                    Cumplimos con las normativas de protección de datos y cooperamos con las autoridades reguladoras cuando es necesario. Revisamos periódicamente esta Política de Privacidad para asegurarnos de tratar la información de conformidad con ella.                    
                </Text>
                <Subtitle>ACTUALIZACIONES DE ESTA POLÍTICA</Subtitle>
                <Text>
                    Podemos actualizar esta Política de Privacidad de vez en cuando. Te notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página. Te recomendamos revisar esta Política periódicamente para estar informado sobre cómo protegemos tu información.                    
                </Text>
                <Subtitle>CONTACTO</Subtitle>
                <Text>
                    Si tienes alguna pregunta sobre esta Política de Privacidad, por favor contáctanos a{' '}
                    <a className="text-orange-500" href="mailto:sistemasmasfletes@gmail.com">sistemasmasfletes@gmail.com</a>.                    
                </Text>
            </div>
        </div>
    )
}

export default AvisoPrivacidad

const Title = ({ children }) => <h1 className="text-black dark:text-white font-bold text-2xl mb-2">{children}</h1>;

const Subtitle = ({ children }) => <h2 className="text-black dark:text-white font-bold text-lg my-1 mt-3">{children}</h2>;

const Text = ({ children }) => <p className="text black dark:text-gray-200 text-justify">{children}</p>;

const Points = ({ items }) => (
  <ul>
    {items.map((item, index) => (
        <li key={item.id} className="text black dark:text-gray-200 text-justify ml-2">
            <strong>{item.id}.</strong> {item.content}
        </li>
    ))}
  </ul>
)

const MidlePoints = ({ items }) => (
    <ul>
        {items.map((item, index) => (
            <li key={item.id} className=" text black dark:text-gray-200 text-justify ml-2">
                <strong>&middot; {item.id}:</strong> {item.content}
            </li>
        ))}
    </ul>
)