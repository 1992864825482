import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React from 'react'
import Home from '../screens/Home'
import AvisoPrivacidad from '../view/AvisoPrivacidad'

function AppRouter() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/aviso-privacidad' element={<AvisoPrivacidad />} />
    </Routes>
    </BrowserRouter>
  )
}

export default AppRouter